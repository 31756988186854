import React, { FC } from "react";
import * as styles from "./SingleWebinar.module.scss";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import { GatsbyImage } from "gatsby-plugin-image";
import linkedin from "../../../images/linkedin.svg";
import twitter from "../../../images/twitter.svg";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import { pathWithoutSpace } from "../../ReportsUiComponents/ReportsMainHelpers";
import { ImageUrlCheck } from "../../Shared/ImageUrlCheck/ImageUrlCheck";
import { UseImageUrl } from "../../hooks/UseImageUrl";
import FooterBanner from "../../Shared/FooterBanner/FooterBanner";
import HeroBanner from "../../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../../Shared/HeroBannerMobile/HeroBannerMobile";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";

import { videos } from "../../WatchNowUiComponents/talk.data";
import LinkWithDescriptionComponent from "../../Shared/LinkWithDescriptionComponent/LinkWithDescriptionComponent";
import translate from "../../../i18n/translate";

type Props = {
  data: any;
};

const SingleWebinar: FC<Props> = ({ data }) => {
  const width = UseWindowSize();
  const registerPath = pathWithoutSpace(data.title);
  UseImageUrl(data.keyTopicsImage, data._rawKeyTopicsImage);
  UseImageUrl(data.detailImage, data._rawDetailImage);
  UseImageUrl(data.bottomImage, data._rawBottomImage);
  const webinarDate = data.date;
  const today = new Date().toISOString();
  let buttonText = "";
  today > webinarDate
    ? (buttonText = "Watch Now")
    : (buttonText = "Register Now");

  return (
    <div className={styles.singleWebinar}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={
            data.eventTag === "re:Wind" ? translate("rewind-title") : data.title
          }
          description={
            data.eventTag === "re:Wind"
              ? translate("rewind-subtitle")
              : data.subtitle
          }
          homeBackground={ImageUrlCheck(data._rawMainImage)}
          buttonText={
            data?.CTA
              ? data.CTA
              : data.eventTag === "re:Wind"
              ? translate("rewind-button")
              : buttonText
          }
          date={data.date}
          link={`/webinars/register/${registerPath}`}
          alt="webinar Page"
        />
      ) : (
        <InnerWrapper>
          <HeroBanner
            heading={
              data.eventTag === "re:Wind"
                ? translate("rewind-title")
                : data.title
            }
            description={
              data.eventTag === "re:Wind"
                ? translate("rewind-subtitle")
                : data.subtitle
            }
            homeBackground={ImageUrlCheck(data._rawMainImage)}
            buttonText={
              data?.CTA || data.eventTag === "re:Wind"
                ? translate("rewind-button")
                : buttonText
            }
            date={data.date}
            link={`/webinars/register/${registerPath}`}
            isImageCentered={true}
          />
        </InnerWrapper>
      )}
      <InnerWrapper>
        <div className={styles.webinarSpeaker}>
          {data.eventTag !== "re:Wind" && <h2>.Speakers</h2>}
          <div className={styles.webinarSpeakerInner}>
            {data.speakers.map((speaker: any) => {
              UseImageUrl(speaker, speaker._rawImage);
              return (
                <div className={styles.speakerRow} key={speaker.name}>
                  <GatsbyImage
                    image={speaker.image.asset.gatsbyImageData}
                    alt={speaker?.alt || "Single Webinar"}
                    className={styles.speakerImage}
                  />
                  <h3>{speaker.name}</h3>
                  <p>{speaker.position}</p>
                  <a href={speaker.linkedin} target="_blank" rel="noreferrer">
                    <img src={linkedin} alt="linkedin" />
                  </a>
                  {speaker.instagram && (
                    <a
                      href={speaker.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={twitter}
                        alt="twitter"
                        style={{ marginLeft: "15px" }}
                      />
                    </a>
                  )}
                </div>
              );
            })}
          </div>
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.8}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
        </div>

        <div className={styles.reportKeyTopics}>
          {data.eventTag !== "re:Wind" && width.width > 576 && (
            <h2>.Key Topics</h2>
          )}

          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />

          <div className={styles.reportKeyTopicsRow}>
            <div className={styles.topicsColumbLeft}>
              <GatsbyImage
                image={data.keyTopicsImage.asset.gatsbyImageData}
                alt={data?.altKeyTopicsImage || "Single Webinar Key Topic"}
                className={styles.keyTopicsImage}
              />
              {width.width <= 576 && data.eventTag !== "re:Wind" && (
                <h2>.Key Topics:</h2>
              )}
            </div>
            <div
              className={
                data.eventTag !== "re:Wind"
                  ? styles.topicsColumbRight
                  : styles.topicsColumnRightRewind
              }
            >
              <div className={styles.topicsItemBox}>
                {data.eventTag !== "re:Wind" &&
                  data.keytopics.map((topic: string, index: number) => (
                    <div key={index} className={styles.topicsItem}>
                      <h5>0{index + 1}</h5>
                      <p>{topic}</p>
                    </div>
                  ))}
              </div>
              {data.eventTag === "re:Wind" && (
                <LinkWithDescriptionComponent
                  isVideoShow={false}
                  order2={1}
                  order1={2}
                  episodes={videos}
                />
              )}
              <div style={{ marginLeft: "60px", marginTop: "-30px" }}>
                <CustomButton
                  image={arrowRightWhite}
                  linkPath={`/webinars/register/${registerPath}`}
                  text={data.eventTag === "re:Wind" ? "Watch now" : buttonText}
                />
              </div>
            </div>
          </div>
        </div>

        {data.eventTag !== "re:Wind" && (
          <div className={styles.singleReportDetails}>
            <div className={styles.detailsBoxLeft}>
              <h2>.Details</h2>
              <p>{data.details}</p>
            </div>
            <div className={styles.detailsBoxRight}>
              <GatsbyImage
                image={data.detailImage.asset.gatsbyImageData}
                className={styles.detailsImage}
                alt={data?.altDetailImage || "Single Webinar Details"}
              />
            </div>
          </div>
        )}
      </InnerWrapper>

      <FooterBanner
        bottomText=".Stay tuned"
        bottomParagraph="Subscribe to get the latest insights on the cloud"
        ButtonLink={`/webinars/register/${registerPath}`}
        FormProp
      />
    </div>
  );
};

export default SingleWebinar;
