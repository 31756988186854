import React, { FC, ReactElement } from "react";
import footerTopBanner from "../../../images/mobile-images/footerTopBanner.webp";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import * as styles from "./FooterBanner.module.scss";
import CustomButton from "../CustomButton/CustomButton";
import greenArrowRightBlog from "../../../images/greenArrowRightBlog.svg";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../CircleOutlineAndBlur/CircleOutline";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";

type Inputs = {
  email: string;
};
type Props = {
  data?: any;
  bottomText: string | ReactElement;
  bottomParagraph: string | ReactElement;
  FormProp?: boolean;
  ButtonLink?: string;
};
const FooterBanner: FC<Props> = ({
  bottomText,
  bottomParagraph,
  FormProp,
  ButtonLink,
  data,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const { width } = UseWindowSize();

  const onSubmit = (data: any) => {
    navigate("/newsletter/thank-you");
  };
  return (
    <>
      <div className={styles.bottomContainer}>
        <img
          src={footerTopBanner}
          alt="footer banner"
          className={styles.bottomImage}
        />
        <div className={styles.bottomTextContainer}>
          <div className={styles.backgroundCircles}>
            {width > 770 ? (
              <>
                <CircleOutline
                  isBlurVisible={false}
                  initialScale={0.3}
                  animateScale={1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.contactOutlineCircle}
                />
                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurGreen}
                />

                <CircleBlur
                  initialOpacity={0}
                  animateOpacity={0.1}
                  transitionType={"easeIn"}
                  transitionDelay={0.2}
                  transitionDuration={1}
                  circleStyleClass={styles.aboutMainCircleBlurBlue}
                />
              </>
            ) : null}
          </div>
          <div>
            <h2>{bottomText}</h2>
            <p>{bottomParagraph}</p>
          </div>
          <div className={styles.bottomTextContainerSubSpan}>
            {FormProp ? (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <fieldset className={styles.fieldInput}>
                    <input
                      className={styles.formInput}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="email@example.com"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                      })}
                    />
                    <span
                      className={styles.formSubmit}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Send &nbsp;&nbsp;
                      <img src={greenArrowRightBlog} alt="right arrow" />
                    </span>
                  </fieldset>
                </form>
              </>
            ) : ButtonLink ? (
              <CustomButton
                image={arrowRightWhite}
                linkPath={ButtonLink}
                text={"Register Now"}
              />
            ) : null}

            {errors.email || errors.email?.message ? (
              <span
                style={{
                  color: "#ff3333",
                  marginTop: "10px",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                }}
              >
                Please enter a valid email
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterBanner;
