// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "LinkWithDescriptionComponent-module--aboutMainCircleBlurBlue--ZTbq9";
export var bHeading = "LinkWithDescriptionComponent-module--bHeading--dvcvP";
export var development = "LinkWithDescriptionComponent-module--development--dB4xM";
export var gdnPromotePic = "LinkWithDescriptionComponent-module--gdnPromotePic--aj75w";
export var head = "LinkWithDescriptionComponent-module--head--56rY2";
export var heroBackgroundCircleLeft = "LinkWithDescriptionComponent-module--heroBackgroundCircleLeft--CmNEu";
export var heroBackgroundCircleLeftStory = "LinkWithDescriptionComponent-module--heroBackgroundCircleLeftStory--2p6vQ";
export var heroBackgroundCircleLeftStorySecond = "LinkWithDescriptionComponent-module--heroBackgroundCircleLeftStorySecond--gzKp7";
export var heroBackgroundInnerCircleLeft = "LinkWithDescriptionComponent-module--heroBackgroundInnerCircleLeft--GFcvX";
export var heroBackgroundInnerCircleLeftSecond = "LinkWithDescriptionComponent-module--heroBackgroundInnerCircleLeftSecond--zdMCD";
export var knowUs = "LinkWithDescriptionComponent-module--knowUs--F0DEn";
export var knowUsHeading = "LinkWithDescriptionComponent-module--knowUsHeading--6JBXN";
export var knowUsImage = "LinkWithDescriptionComponent-module--knowUsImage--5mdBl";
export var knowUsImageDiv = "LinkWithDescriptionComponent-module--knowUsImageDiv--i9J-2";
export var knowUsOurStory = "LinkWithDescriptionComponent-module--knowUsOurStory--3lOUB";
export var knowUsOurStoryText = "LinkWithDescriptionComponent-module--knowUsOurStoryText--HD52+";
export var knowUsText = "LinkWithDescriptionComponent-module--knowUsText--yOxs0";
export var ourStoryDescPara = "LinkWithDescriptionComponent-module--ourStoryDescPara--bgJ3c";
export var ourStoryImg = "LinkWithDescriptionComponent-module--ourStoryImg--nAWhb";
export var ourStoryImgDiv = "LinkWithDescriptionComponent-module--ourStoryImgDiv--e6XB-";
export var ourStoryText = "LinkWithDescriptionComponent-module--ourStoryText--+sLBG";
export var storyPromotePic = "LinkWithDescriptionComponent-module--storyPromotePic--iYLxa";
export var storyPromotePicSecond = "LinkWithDescriptionComponent-module--storyPromotePicSecond--OmQyO";
export var testimonialVideo = "LinkWithDescriptionComponent-module--testimonialVideo--tClKq";
export var textWraper = "LinkWithDescriptionComponent-module--textWraper--we5Em";
export var topHeading = "LinkWithDescriptionComponent-module--topHeading--r8MCC";