import React, { FC } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import SingleWebinar from "../components/WebinarsUiComponents/SingleWebinar/SingleWebinar";
import { SEO } from "../components/seo";

import { ImageSeoUrlCheck } from "../components/Shared/ImageSeoUrlCheck/ImageSeoUrlCheck";
type Props = {
  data: any;
};

const WebinarTemplate: FC<Props> = ({ data }) => {
  const webinar = data.sanityWebinar;
  return (
    <Layout location="/blog">
      <SEO
        title={webinar.title}
        description={webinar.subtitle}
        type="website"
        image={ImageSeoUrlCheck(data.sanityWebinar._rawMainImage.asset.url)}
      />
      <SingleWebinar data={webinar} />
    </Layout>
  );
};

export default WebinarTemplate;

export const query = graphql`
  query ($slug: String!) {
    sanityWebinar(id: { eq: $slug }) {
      _rawMainImage(resolveReferences: { maxDepth: 20 })
      _rawKeyTopicsImage(resolveReferences: { maxDepth: 20 })
      _rawDetailImage(resolveReferences: { maxDepth: 20 })
      _rawBottomImage(resolveReferences: { maxDepth: 20 })
      id
      date
      bottomText
      detailImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altDetailImage
      details
      eventTag
      keytopics
      keyTopicsImage {
        _rawAsset(resolveReferences: { maxDepth: 20 })
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altKeyTopicsImage
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      altMainImage
      speakers {
        _rawImage
        name
        position
        image {
          asset {
            gatsbyImageData(fit: FILLMAX)
          }
        }
        alt
        instagram
        linkedin
      }
      subtitle
      title
      url
      bottomImage {
        asset {
          gatsbyImageData
        }
      }
      altBottomImage
      CTA
    }
  }
`;
