// extracted by mini-css-extract-plugin
export var bottomButton = "SingleWebinar-module--bottomButton--A0uda";
export var bottomContainer = "SingleWebinar-module--bottomContainer--KBJWC";
export var bottomImage = "SingleWebinar-module--bottomImage--mjHNq";
export var bottomTextContainer = "SingleWebinar-module--bottomTextContainer--lXzxQ";
export var contactButton = "SingleWebinar-module--contactButton--TjP-9";
export var detailsBoxLeft = "SingleWebinar-module--detailsBoxLeft--r9FC1";
export var detailsBoxRight = "SingleWebinar-module--detailsBoxRight--tx8wW";
export var detailsImage = "SingleWebinar-module--detailsImage--X3nB4";
export var heroBackgroundCircleLeft = "SingleWebinar-module--heroBackgroundCircleLeft--KJh1k";
export var heroBackgroundInnerCircleLeft = "SingleWebinar-module--heroBackgroundInnerCircleLeft--a9n9X";
export var keyTopicsImage = "SingleWebinar-module--keyTopicsImage--bNJu8";
export var reportKeyTopics = "SingleWebinar-module--reportKeyTopics--w2cKf";
export var reportKeyTopicsRow = "SingleWebinar-module--reportKeyTopicsRow--uzi62";
export var singleReportDetails = "SingleWebinar-module--singleReportDetails--mZfQw";
export var singleWebinar = "SingleWebinar-module--singleWebinar--2hHEH";
export var speakerImage = "SingleWebinar-module--speakerImage--KxZLF";
export var speakerRow = "SingleWebinar-module--speakerRow--DU+yB";
export var topicsColumbLeft = "SingleWebinar-module--topicsColumbLeft--NuGa5";
export var topicsColumbRight = "SingleWebinar-module--topicsColumbRight--Wr5nD";
export var topicsColumnRightRewind = "SingleWebinar-module--topicsColumnRightRewind--YiR0p";
export var topicsItem = "SingleWebinar-module--topicsItem--8S4B2";
export var topicsItemBox = "SingleWebinar-module--topicsItemBox--jZPgp";
export var webinarSpeaker = "SingleWebinar-module--webinarSpeaker--GN3b8";
export var webinarSpeakerInner = "SingleWebinar-module--webinarSpeakerInner--+aSOO";